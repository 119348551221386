export const checksums = {
  "content": "Tn1nRkosG4"
}

export const tables = {
  "content": "_content_content",
  "info": "_content_info"
}

export default {
  "content": {
    "jsonFields": [
      "meta",
      "seo",
      "body",
      "navigation",
      "tags",
      "versions"
    ]
  },
  "info": {
    "jsonFields": []
  }
}